import { render, staticRenderFns } from "./RisksView.vue?vue&type=template&id=43442a65&scoped=true&"
import script from "./RisksView.vue?vue&type=script&lang=js&"
export * from "./RisksView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "43442a65",
  null
  
)

export default component.exports