import {make} from 'vuex-pathify'

const state = {
  refresh_key: 0, // forces re-render of frontier chart with fresh API resp.
  stale_chart: false,
  api_waiting: null, // visual indicator that we're waiting on API resp.
  slice: 4, // choice of portfolio to render in pie chart. higher = riskier.
  asset_risks: [
    0.0433225589410411,
    0.020292463535698812,
    0.01742562991090338,
    0.043034205129505995,
    0.046224520271782,
    0.026137290176332092
  ],
  asset_returns: [
    0.004243105470109206,
    0.004740885892604311,
    0.0038931556596308327,
    0.0038726009697606658,
    0.006895812759224104,
    0.005207365198387246
  ],
  asset_labels: [
    "Gold",
    "EM Corp USD Bonds",
    "US BBB Corps",
    "Nikkei 225",
    "US Stocks",
    "US HY Bonds"
  ],
  portfolio_risks: [
    0.01759338546151734,
    0.02073134107369933,
    0.02386719349035493,
    0.027065588027810533,
    0.030261408344215746,
    0.03347918275768523,
    0.03665891620633142,
    0.039857061140395035,
    0.04305584483030225,
    0.04618706095445578
  ],
  portfolio_returns: [
    0.004029669948944837,
    0.004919923068260595,
    0.005340581383118387,
    0.005625466189565673,
    0.005867570678641789,
    0.006090103466513332,
    0.006286326854603447,
    0.00649440882029283,
    0.006692924658920734,
    0.006892134553997281
  ],
  portfolio_weights: [
    [0.0463259608610192,0.04177612157197601,0.0018604074450746058,0.001858952520764547,0.0027965070691935118,0.003956795309394476,0.006191443984371141,0.005588098766751672,0.0062124570231974425,0.00015598555209900663],
    [0.09481536415738237,0.8619055399337194,0.7187671520889124,0.5804165832465809,0.4572649430039791,0.33581253805791533,0.061906311429155286,0.02072865545268495,0.01655062179052444,0.0001922699334332991],
    [0.7964021027187066,0.001644465056715216,0.00016518276309915582,0.0008720416827137232,0.0015432427623999867,0.0023788216588197858,0.003594516702066358,0.0036337176553066634,0.00455964768924778,0.00020549333513346116],
    [0.04826576783084517,0.0005220556313596938,4.035949502721115e-05,0.00027955179670856263,0.0005247573495923406,0.0008172189096978187,0.001375389199046213,0.001690603065632667,0.0028976843102146983,0.0006647341119001942],
    [0.013187898281370555,0.09343493747308614,0.2787004280870565,0.40994166851210745,0.5205582125599768,0.6203451828174867,0.6635501296672647,0.7753474508625499,0.8937976506178601,0.9986491851252729],
    [0.0010029061506761192,0.0007168803331436855,0.00046647012083027165,0.006631202241124847,0.017312337254858343,0.03668944324668573,0.2633822090180964,0.19301147419707432,0.07598193856895555,0.0001323319421611752]
  ]
}

const mutations = {
  ...make.mutations(state),
}

export default {
  namespaced: true,
  state,
  mutations,
}
